import React from 'react';
import Navbar from '../../components/home/navbar/Navbar';
import './documentation.css';
// import img1 from '../../assets/images/code-execution1.png'
// import img2 from '../../assets/images/image2.png'
// import dashboard from '../../assets/images/dashboard.png';
import img3 from '../../assets/images/image3.png';
// import code from '../../assets/images/image-code.png'
import arduinoSetup1 from '../../assets/documentation/arduino-setup-1.png';
import arduinoSetup2 from '../../assets/documentation/arduino-setup-2.png';
import arduinoSetup3 from '../../assets/documentation/arduino-setup-3.png';
import codeExecution1 from '../../assets/documentation/code-execution-1.png';
import codeExecution2 from '../../assets/documentation/code-execution-2.png';
import codeExecution3 from '../../assets/documentation/code-execution-3.png';
import codeExecution4 from '../../assets/documentation/code-execution-4.png';
import codeExecution5 from '../../assets/documentation/code-execution-5.png';
import codeExecution6 from '../../assets/documentation/code-execution-6.png';
import codeExecution7 from '../../assets/documentation/code-execution-7.png';
import codeExecution8 from '../../assets/documentation/code-execution-8.png';
import codeExecution9 from '../../assets/documentation/code-execution-9.png';
import codeExecution10 from '../../assets/documentation/code-execution-10.png';
import codeExecution11 from '../../assets/documentation/code-execution-11.png';
import codeExecution12 from '../../assets/documentation/code-execution-12.png';
import codeExecution13 from '../../assets/documentation/code-execution-13.png';
import codeExecution14 from '../../assets/documentation/code-execution-14.png';
import package1 from '../../assets/documentation/package-1.png';
import package2 from '../../assets/documentation/package-2.png';
import package3 from '../../assets/documentation/package-3.png';
import package4 from '../../assets/documentation/package-4.png';
import package5 from '../../assets/documentation/package-5.png';
import package6 from '../../assets/documentation/package-6.png';
import package7 from '../../assets/documentation/package-7.png';
import package8 from '../../assets/documentation/package-8.png';
import copyIcon from '../../assets/documentation/copy.svg';
import installAll from '../../assets/documentation/install-all.png';

const copyToClipboard = (text) => {
        console.log('text', text)
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        navigator.clipboard.writeText(text);
        alert(`text copied to clipboard: ${text}`);
}

export default function DocumentationNewVersion() {

    return (
        <div>
            <Navbar />
            <div className="mx-auto px-4 mt-40">
                <div className="grid grid-cols-1 lg:grid-cols-5">
                    <div className="lg:col-span-1 max-w-[300px] bg-[#FDFFFD] hidden lg:flex flex-col gap-10 ml-6 items-start p-4 h-screen ">
                        <div className='documentation-menu-items'>
                            <a href="#introduction" className="menu-item">Prerequisites</a>
                            <a href="#arduino-ide" className="menu-item">Arduino IDE Setup</a>
                            <a href="#executing-code" className="menu-item" >Code Execution</a>
                            <a href="#trouble" className="menu-item">Troubleshooting</a></div>
                        </div>

                    <div className="lg:col-span-4 flex flex-col items-start gap-4">
                        <div className='place-self-center flex flex-row text-[#1E1147] text-[30px] font-[700] h-[70px]'>How to use Sensy32 Board</div>

                        <div className='text-[#1E1147] text-[20px] font-[500]'>
                            This is the official documentation to use Sensy32 board along with <a className='text-[#168004] text-[20px] font-[500]' href='https://sensy32.io/'>Sensy32.io</a> platform.
                        </div>
                        
                        <div className='text-[#1E1147] text-[20px] font-[500]'>
                            A comprehensive technical guide can be downloaded <a className='text-[#168004] text-[20px] font-[500]' href='https://sensy32.io/Technical-Guide-to-use-Sensy32-board.pdf' download="Technical-Guide-to-use-Sensy32-board.pdf">From Here</a>.
                        </div>

                        <div className='text-[#1E1147] text-[20px] font-[500]'>
                            All code samples are available in our official <a className='text-[#168004] text-[20px] font-[500]' href='https://github.com/sensy32/sensy32/tree/main'>Github Repository</a>.
                        </div>

                        <div className='text-[#1E1147] text-[20px] font-[500]'>
                            Tutorial videos are provided on our official <a className='text-[#168004] text-[20px] font-[500]' href='https://www.youtube.com/@Sensy32'>Youtube Channel</a> to guide you through using the Sensy32 board effectively.
                        </div>

                        <div id='introduction' className='text-[#168004] text-[24px] font-[700]'>Prerequisites: </div>
                        
                        <div className='text-[#1E1147] text-[18px] font-[500]'>1. Make sure to install both the <b>Arduino IDE</b> software and the <b>CP210xVCP Driver</b>:</div>

                        <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>Arduino IDE: <a className='text-[#168004] text-[20px] font-[500]' href='https://www.arduino.cc/en/software'>Download from here</a></li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>CP210xVCP Driver: <a className='text-[#168004] text-[20px] font-[500]' href='https://www.silabs.com/developers/usb-to-uart-bridge-vcp-drivers'>Download from here</a></li>
                        </div>
                            
                        <div className='text-[#1E1147] text-[18px] font-[500]'>2. Ensure you have a Sensy32 board. If you don’t have one yet, you can get it from here: <a className='text-[#168004] text-[20px] font-[500]' href='https://www.tindie.com/products/sensy32/sensy32-all-in-one-sensor-iot-board-with-lcd/?pt=ac_prod_search'>Get from here</a></div>


                        <div id='arduino-ide' className='text-[#168004] text-[24px] font-[700]'>Arduino IDE Setup: </div>

                        <div className='text-[#1E1147] text-[18px] font-[500]'>3. Click on the <b>Arduino IDE</b> tab in the top-left corner, then select <b>Settings</b>.</div>
                        <img alt="arduino setup 1" src={arduinoSetup1} width="200px"/>
                        <div className='text-[#1E1147] text-[18px] font-[500]'>
                            In the <b>Settings</b> menu, add the following link to the <b>Additional Boards Manager URLs</b> field: 
                            <div className='flex flex-row gap-[10px]'>
                                <a className='text-[#168004] text-[20px] font-[500]' href='https://dl.espressif.com/dl/package_esp32_index.json'>https://dl.espressif.com/dl/package_esp32_index.json</a><img alt="copy" onClick={()=>copyToClipboard('https://dl.espressif.com/dl/package_esp32_index.json')} className='cursor-pointer' src={copyIcon} width="20px"/>
                            </div>
                        </div>
                        <div className='text-[#1E1147] text-[18px] font-[500]'>After adding the link, click the <b>OK</b> button.</div>
                        <img alt="arduino setup 2" src={arduinoSetup2} width="800px"/>
                        <div className='text-[#1E1147] text-[18px] font-[500]'>4. Next, download the ESP32 board manager by navigating to the <b>Boards Manager</b> under the second menu tab (vertically). Look for the <b>ESP32 by Espressif Systems</b> (it's the second one in the list) and install it.</div>
                        <img alt="arduino setup 3" src={arduinoSetup3}  width="250px"/>

                        <div id='executing-code' className='text-[#168004] text-[24px] font-[700]'>Code Execution: </div>

                        <div className='text-[#1E1147] text-[18px] font-[500]'>5. Open a new sketch in the Arduino IDE and copy-paste the content from one of the following files, depending on the measurement you want to perform:</div>
                        
                        <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>Temperature</b>, <b>Humidity</b>, <b>Altitude</b>, and <b>Pressure</b>: use the file &nbsp;
                                <a className='text-[#168004] text-[20px] font-[500]' href='https://github.com/sensy32/sensy32/blob/main/examples/temperature-humidity-sensor.ino'>temperature-humidity-sensor.ino</a>.
                            </li>

                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>Altitude</b>, <b>Pressure</b>, and <b>Temperature</b>: use the file &nbsp;
                                <a className='text-[#168004] text-[20px] font-[500]' href='https://github.com/sensy32/sensy32/blob/main/examples/pressure-altitude-sensor.ino'>pressure-altitude-sensor.ino</a>.
                            </li>

                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>UV light value</b>: use the file &nbsp;
                                <a className='text-[#168004] text-[20px] font-[500]' href='https://github.com/sensy32/sensy32/blob/main/examples/uv-sensor.ino'>uv-sensor.ino</a>.
                            </li>

                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>Orientation</b> (<b>quatI</b>, <b>quatJ</b>, <b>quatK</b>, <b>quatReal</b>, <b>quatRadianAccuracy</b>): use the file &nbsp;
                                <a className='text-[#168004] text-[20px] font-[500]' href='https://github.com/sensy32/sensy32/blob/main/examples/orientation-sensor.ino'>orientation-sensor.ino</a>.
                            </li>

                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>IR motion and human presence</b>: use the file &nbsp;
                                <a className='text-[#168004] text-[20px] font-[500]' href='https://github.com/sensy32/sensy32/blob/main/examples/motion-sensor.ino'>motion-sensor.ino</a>.
                            </li>
                            
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>Light Intensity</b>(<b>IR</b>, <b>Full</b>, <b>Visible</b>, <b>Lux</b>): use the file &nbsp;
                                <a className='text-[#168004] text-[20px] font-[500]' href='https://github.com/sensy32/sensy32/blob/main/examples/light-sensor.ino'>light-sensor.ino</a>.
                            </li>

                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>Accelerometer</b>(<b>X</b>, <b>Y</b>, <b>Z</b>): use the file &nbsp;
                                <a className='text-[#168004] text-[20px] font-[500]' href='https://github.com/sensy32/sensy32/blob/main/examples/accelerometer-sensor.ino'>accelerometer-sensor.ino</a>.
                            </li>

                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>All Measurements at Once</b>: use the file &nbsp;
                                <a className='text-[#168004] text-[20px] font-[500]' href='https://github.com/sensy32/sensy32/blob/main/examples/all_sensors.ino'>all_sensors.ino</a>.
                            </li>
                        </div>
                        
                        <div className='text-[#1E1147] text-[18px] font-[500]'>6. Now, connect your Sensy32 board to your computer using a USB Type-C cable.</div>

                        <div className='text-[#168004] text-start text-[16px] font-[700]'>
                            Note: &nbsp;
                            <span className='text-[#1E1147] text-start text-[16px] font-[500]'>
                                To detect whether the board is successfully connected. You can use the commands below based on your operating system. They show a list of specific devices or files related to serial communication on your system. &nbsp;
                                <div className='ml-[50px] text-[#1E1147] text-[16px] font-[500]'>
                                    <li className='text-[#1E1147] text-[16px] font-[500] flex flex-row gap-[10px]'>
                                        Macos:  <span className='text-[#1E1147] text-[20px] font-[700]'> ls /dev/cu.* </span><img onClick={()=>copyToClipboard('ls /dev/cu.*')} alt="copy" className='cursor-pointer' src={copyIcon} width="20px"/>
                                    </li>
                                    <li className='text-[#1E1147] text-[16px] font-[500] flex flex-row gap-[10px]'>
                                        Windows:  <span className='text-[#1E1147] text-[20px] font-[700]'> [System.IO.Ports.SerialPort]::GetPortNames() </span><img onClick={()=>copyToClipboard('[System.IO.Ports.SerialPort]::GetPortNames()')} alt="copy" className='cursor-pointer' src={copyIcon} width="20px"/>
                                    </li>
                                    <li className='text-[#1E1147] text-[16px] font-[500] flex flex-row gap-[10px]'>
                                        Ubuntu:  <span className='text-[#1E1147] text-[20px] font-[700]'> ls /dev/ttyUSB* </span><img onClick={()=>copyToClipboard('ls /dev/ttyUSB*')} alt="copy" className='cursor-pointer' src={copyIcon} width="20px"/>
                                    </li>
                                </div>
                            </span>
                        </div>

                        <div className='text-[#1E1147] text-[18px] font-[500]'>7. Next, click on the Sketch tab in the Arduino IDE, select Add File, and upload the <a className='text-[#168004] text-[20px] font-[500]' href='https://github.com/sensy32/sensy32/blob/main/examples/config.h'>config.h</a> file as demonstrated in the screenshots below:</div>
                        <div className='flex flex-col md:flex-row gap-[50px]'>
                            <img alt="code execution 1" src={codeExecution1} width="300px"></img>
                            <img alt="code execution 2" src={codeExecution2}  width="500px"></img>
                        </div>

                        <div className='text-[#1E1147] text-[18px] font-[500]'>8. Now, update the following variables in the <b>config.h</b> file:</div>
                        <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>apiKey</b>: Replace it with your board's API key (obtained when you create a new board on <a className='text-[#168004] text-[20px] font-[500]' href='https://sensy32.io'>https://sensy32.io</a>).
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>ssid</b> and <b>password</b>: Replace them with your Wi-Fi credentials.
                            </li>
                        </div>
                        <img alt="code execution 3" src={codeExecution3} width="800px"></img>

                        <div className='text-[#1E1147] text-[18px] font-[500]'>9. Now, download the required packages specified in the <b>config.h</b> file and any additional dependencies referenced in the other file you're using. </div>
                        <div className='flex flex-col md:flex-row gap-[30px]'>
                            <img alt="code execution 3" src={codeExecution4} width="400px"></img>
                            <img alt="code execution 4" src={codeExecution5}  width="600px"></img>
                        </div>

                        <div className='text-[#1E1147] text-[18px] font-[500]'>Some packages are pre-installed, and you only need to install the missing ones through the <b>Library Manager</b> (accessible from the third menu tab).</div>
                        {/* <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>If using </b> <span className='text-[#168004] text-[20px] font-[500]'>all_sensors.ino</span>:  Install all the packages listed in the screenshots.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>If using a specific sensor file </b> (<b>e.g., </b> <span className='text-[#168004] text-[20px] font-[500]'>temperature-humidity-sensor.ino</span>): Refer to the file header to determine which packages are required, and install only those.
                            </li>
                        </div> */}

                        <div className='text-[#168004] text-start text-[16px] font-[700]'><b>Notes:</b></div>
                        <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-start text-[16px] font-[500]'>
                                These packages only need to be installed once. For instance, if you initially run the <b>all_sensors.ino </b> file, you won’t need to install additional packages when switching to <b>temperature-humidity-sensor.ino</b> or any other individual sensor file.
                            </li>
                            <li className='text-[#1E1147] text-start text-[16px] font-[500]'>
                                In the rare case you’re prompted for other packages, simply search for their names in the Library Manager and install them as shown in the examples. However, this is usually unnecessary.
                            </li>
                        </div>

                        <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4'>
                            <div>
                                <div className='flex flex-row gap-[10px]'>
                                    <b>ss_oled</b><img onClick={()=>copyToClipboard('ss_oled')} alt="copy" className='cursor-pointer' src={copyIcon} width="15px"/>
                                </div>
                                <img alt="package 1" src={package1}></img>
                            </div>
                            <div>
                                <div className='flex flex-row gap-[10px]'>
                                    <b>adafruit tsl2591</b><img onClick={()=>copyToClipboard('adafruit tsl2591')} alt="copy" className='cursor-pointer' src={copyIcon} width="15px"/>
                                </div>
                                <img alt="package 2" src={package2}></img>
                            </div>
                            <div>
                                <div className='flex flex-row gap-[10px]'>
                                    <b>sparkfun bno08x</b><img onClick={()=>copyToClipboard('sparkfun bno08x')} alt="copy" className='cursor-pointer' src={copyIcon} width="15px"/>
                                </div>
                                <img alt="package 3" src={package3}></img>
                            </div>
                            <div>
                                <div className='flex flex-row gap-[10px]'>
                                    <b>sparkfun sths34pf80</b><img onClick={()=>copyToClipboard('sparkfun sths34pf80')} alt="copy" className='cursor-pointer' src={copyIcon} width="15px"/>
                                </div>
                                <img alt="package 4" src={package4}></img>
                            </div>
                            <div>
                                <div className='flex flex-row gap-[10px]'>
                                    <b>bmp388 dev</b><img onClick={()=>copyToClipboard('bmp388 dev')} alt="copy" className='cursor-pointer' src={copyIcon} width="15px"/>
                                </div>
                                <img alt="package 5" src={package5}></img>
                            </div>
                            <div>
                                <div className='flex flex-row gap-[10px]'>
                                    <b>sparkfun bme280</b><img onClick={()=>copyToClipboard('sparkfun bme280')} alt="copy" className='cursor-pointer' src={copyIcon} width="15px"/>
                                </div>
                                <img alt="package 6" src={package6}></img>
                            </div>
                            <div>
                                <div className='flex flex-row gap-[10px]'>
                                    <b>adafruit ltr390</b><img onClick={()=>copyToClipboard('adafruit ltr390')} alt="copy" className='cursor-pointer' src={copyIcon} width="15px"/>
                                </div>
                                <img alt="package 7" src={package7}></img>
                            </div>
                            <div>
                                <div className='flex flex-row gap-[10px]'>
                                    <b>arduinojson</b><img onClick={()=>copyToClipboard('arduinojson')} alt="copy" className='cursor-pointer' src={copyIcon} width="15px"/>
                                </div>
                                <img alt="package 8" src={package8}></img>
                            </div>
                        </div>
                        <div className='text-[#1E1147] text-[18px] font-[500]'>If prompted to install library dependencies (as shown in the example below), simply click <b>Install All</b> to ensure all necessary libraries are installed.</div>
                        <img alt="install all" src={installAll} width="600px"></img>

                        <div className='text-[#1E1147] text-[18px] font-[500]'>Here is an overview about the role of each package used in our code samples:</div>

                        <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>WiFi.h</b> and <b>WiFiClientSecure.h</b>: These two libraries enable secure connection to and management of Wi-Fi networks.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>Wire.h</b>: Used for I2C communication with the sensor.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>HTTPClient.h</b>: This library provides support for making HTTP requests and responses.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>ss_oled.h</b>: This library is used to configure and display data on the LCD screen.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>ArduinoJson.h</b>: This library is used for serializing and deserializing JSON objects to facilitate data exchange with the server.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>SparkFunBME280.h</b>: This library helps work with the SparkFun BME280 sensor, allowing you to read temperature, humidity, and pressure data.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>BMP388_DEV.h</b>: This library facilitates interaction with the BMP388 sensor module.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>Adafruit_LTR390.h</b>: A library for communicating with the Adafruit_LTR390 UV sensor.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>Adafruit_TSL2591.h</b>: A library for communicating with the Adafruit_TSL2591 Light sensor.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>Adafruit_Sensor.h</b>: Provides common functionality for working without various sensors, simplifying sensor integration in projects.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>SparkFun_BNO08x_Arduino_Library.h</b>: Simplifies interfacing with BNO08x series sensors in Arduino projects.
                            </li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>
                                <b>SparkFun_STHS34PF80_Arduino_Library.h</b>: Simplifies interfacing with the STHS34PF80 sensor in Arduino projects.
                            </li>
                        </div>
                            
                        <div className='text-[#1E1147] text-[18px] font-[500]'>10. In the top-left corner, click the <b>Select Board</b> button, then choose <b>Select Other Board and Port</b> from the dropdown menu. This will open a Popup where you need to:</div>
                        <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>Select <b>ESP32S3 Dev Module</b> as the board.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>Select the serial port appropriate for your operating system: <b>/dev/cu.SLAB_USBtoUART Serial Port (USB)</b> for macOS, <b>COM3 Serial Port (USB)</b> for Windows, and <b> /dev/ttyUSB0 </b>for Ubuntu</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'>Click the <b>OK</b> button.</li>
                        </div>
                        <img alt="code execution 6" src={codeExecution6} width="400px"></img>
                        <div className='flex flex-col md:flex-row gap-[50px]'>
                            <img alt="code execution 7" src={codeExecution7} width="500px"></img>
                            <img alt="code execution 10" src={codeExecution10} width="500px"></img>
                        </div>
                        <div className='text-[#168004] text-start text-[16px] font-[700]'>
                            Note: &nbsp;
                            <div className='ml-[50px]'>
                                <div className='text-[#1E1147] text-[18px] font-[500]'> On Ubuntu, the Arduino IDE interface differs slightly. To install the board manager, add libraries, or select the board and port, navigate to the <b>Tools</b> menu in the top navigation bar.</div>
                                <img alt="code execution 11" src={codeExecution11} width="400px"></img>
                                <div className='text-[#1E1147] text-[18px] font-[500]'> To install libraries you refer to the <b>Manage Libraries</b> option.</div> 
                                <img alt="code execution 12" src={codeExecution12} width="400px"></img>
                                <div className='text-[#1E1147] text-[18px] font-[500]'>
                                    You can select the board and port using the  <b>Board</b> and <b>Port</b> options. To install a board manager, use the <b>Boards Manager</b> option found under the Board menu.
                                </div>
                                <div className='flex flex-col md:flex-row gap-[50px]'>
                                    <img alt="code execution 13" src={codeExecution13} width="500px"></img>
                                    <img alt="code execution 14" src={codeExecution14} width="500px"></img> 
                                </div>
                            </div>
                        </div>
                        <div className='text-[#1E1147] text-[18px] font-[500]'>11. Additionally, open the <b>Serial Monitor</b> console from the <b>Tools</b> tab to view the logs in real time.</div>
                        <img alt="code execution 8" src={codeExecution8} width="600px"></img>

                        <div className='text-[#1E1147] text-[18px] font-[500]'>12. Finally, click the <b>Upload</b> button (the arrow icon) on the navigation bar to upload the code to your board.</div>
                        <img alt="code execution 9" src={codeExecution9} width="600px"></img>
                        <div className='text-[#1E1147] text-[18px] font-[500]'>Once the upload is complete:</div>
                        <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Serial Monitor</b>: Displays real-time values.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Board’s LCD Screen</b>: Shows the sensor data.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Real-Time Data Page on</b> <a className='text-[#168004] text-[20px] font-[500]' href='https://sensy32.io'>Sensy32.io</a>: Displays convenient charts with the data.</li>
                        </div>
                        <div className='text-[#1E1147] text-[18px] font-[500]'>Get ready to watch it all in action! 🎉</div>

                        <div id="trouble" className='text-[#168004] text-[24px] font-[700]'>Troubleshooting: </div>
                        
                        <div className='text-[#168004] text-[18px] font-[700] flex text-start mt-2'>Hardware Issues:</div>
                        <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Power Supply</b>: Make sure your SENSY32 board is connected to a stable power source.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>USB Cable</b>: Use a good-quality USB cable to connect the board to your computer. Avoid damaged cables, and try a different one if needed.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Board Connection</b>: Confirm that your board is correctly connected to the computer. Ensure the USB cable is securely plugged into both the board and the computer's USB port.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>External Components</b>: If your project involves external parts (sensors, actuators), check their connections and power supply, ensuring they are connected to the right pins.</li>
                        </div>
                        <div className='text-[#168004] text-[18px] font-[700] flex text-start mt-2'>Software Issues:</div>
                        <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Compilation Errors</b>: If the sketch compiles but doesn't upload, look for error messages in the Arduino IDE.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Library Issues</b>: If using libraries, make sure you have the latest versions compatible with your SENSY32 board.</li>
                        </div>

                        <div className='text-[#168004] text-[18px] font-[700] flex text-start mt-2'>Additional Tips:</div>
                        <div className='ml-[50px]'>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Serial Monitor</b>: Use the Arduino IDE's Serial Monitor to track your program's output, helping identify errors.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Reset Button</b>: Restart the program by pressing the reset button on the board to resolve temporary glitches.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Check Wi-Fi Credentials</b>: Ensure your code has the correct Wi-Fi credentials; otherwise, it won't work.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Wrong Boot Mode Issue</b>: If facing the "Wrong boot mode detected" problem, follow the solution: Hold Boot button, click EN button, click Upload, release Boot button when "Connecting...." is displayed.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Do not disconnect the wiring</b>: Avoid closing the wiring while the code is being uploaded to the board.</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Serial Monitor Baud Rate</b>: Always check that the baud rate in the serial monitor matches the one in your code.</li>
                            <img className='mx-auto my-auto mt-8' alt="serial print" src={img3} width="600px"/>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Brownout Detector Issue</b>: If encountering the "Brownout detector was triggered" problem, consider the possibilities:</li>
                            <div className='ml-[50px]'>
                                    <li className='text-[#1E1147] text-[18px] font-[500]'>Poor-quality or too-long USB cable.</li>
                                    <li className='text-[#1E1147] text-[18px] font-[500]'>Insufficient power from your computer's USB port.</li>
                                    <li className='text-[#1E1147] text-[18px] font-[500]'>Other components affecting the power supply in your circuit might not be correctly wired up.</li>
                            </div>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Check Port Settings</b>: Always verify that the correct port is selected in the Arduino IDE under "Tools" - "Port."</li>
                            <li className='text-[#1E1147] text-[18px] font-[500]'><b>Review Code Comments</b>: Check for comments in the code that may provide insights or explanations for specific sections.</li>
                        </div>
                        <div className='h-[50px]'></div>
                    </div>
                </div>

            </div>
        </div>
    );
}
