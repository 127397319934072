import React, { useContext, useState } from "react";
import { Modal, Box, FormControlLabel } from "@mui/material";
import axios from "axios";
import { ThemeContext } from "../../../state/ThemeContext";
import { useUser } from "../../../state/UserContext";
import IOSSwitch from "../../IosSwitch";
import CustomSnackbar from "../../CustomSnackbar";
const MAX_LOGO_SIZE = 10000;

const AddBoardModal = ({ open, handleClose, style }) => {
  const { userData } = useUser();
  const [selectedImage, setSelectedImage] = useState(null);
  const theme = useContext(ThemeContext).theme;
  const [addBoardSnackbar, setAddBoardSnackbar] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    logo: "",
    is_realtime: false,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (file.size > MAX_LOGO_SIZE) {
          setErrors({ logo: "Logo size exceeds the limit, please select a smaller logo." });
        } else {
          setErrors({});
          setSelectedImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const addBoard = async () => {
    const board = { ...formData, logo: selectedImage, userId: userData.id };
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/boards/`,
        { board },
        { headers }
      );

      if (response.status === 201) {
        handleCloseModal();
        setAddBoardSnackbar(true);
        const userNbBoards = parseInt(localStorage.getItem("nb_boards")) || 0;
        const updateNbBoards = userNbBoards + 1;
        localStorage.setItem("nb_boards", updateNbBoards);
      }
    } catch (error) {
      console.error("Error adding board:", error);
    }
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const handleSave = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    // if (!selectedImage) {
    //   newErrors.logo = "Logo is required";
    // }

    if (Object.keys(newErrors).length === 0) {
      setErrors({});
      addBoard();
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Box sx={style}>
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-dark_black font-[700] text-[28px]">
              Add board
            </h3>
            <button type="button" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="24"
                viewBox="0 0 27 24"
                fill="none"
              >
                <path
                  d="M20.25 5.93091L6.75 17.3185"
                  stroke="#525256"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.75 5.93091L20.25 17.3185"
                  stroke="#525256"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            className="bg-[#EFF0EF] mb-[25px] mt-[15px]"
            style={{ height: "2px" }}
          />
          <div>
            <form className="flex flex-col">
              <label
                htmlFor="name"
                className={`my-3 text-[15px] font-[500] text-dark_black`}
              >
                Name
              </label>
              <input
                placeholder="Enter board name"
                label="Name"
                name="name"
                type="text"
                fullWidth
                value={formData.name}
                onChange={handleChange}
                className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[54px] ${theme === "dark"
                  ? "bg-[#4A4A4A]"
                  : "bg-white border border-[#D0D5DD]"
                  } `}
              />{" "}
              <span className="text-red-500">{errors.name}</span>
              <label
                htmlFor="image-upload"
                className="my-3 text-[15px] font-[500] text-dark_black"
              >
                Logo
              </label>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="image-upload"
                onChange={handleImageUpload}
              />
              <label htmlFor="image-upload">
                <div
                  className={` rounded-[8px] text-center  mb-[10px]  focus:outline-none p-4 ${theme === "dark"
                    ? "bg-[#4A4A4A]"
                    : "bg-white border border-[#D0D5DD]"
                    } `}
                >
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{
                        width: "70px",
                        objectFit: "contain",
                        display: "block",
                        margin: "0 auto",
                        maxHeight: "100px",
                      }}
                    />
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="61"
                        height="60"
                        viewBox="0 0 71 69"
                        fill="none"
                      >
                        <path
                          d="M19.407 56.7721C14.9737 56.7721 11.1917 55.2893 8.06119 52.3237C4.93063 49.3393 3.36536 45.7032 3.36536 41.4154C3.36536 37.7321 4.50286 34.4454 6.77786 31.5554C9.0723 28.6654 12.0667 26.8237 15.7612 26.0304C16.9862 21.6859 19.4167 18.1726 23.0529 15.4904C26.7084 12.7893 30.8404 11.4387 35.4487 11.4387C41.1459 11.4387 45.9681 13.3654 49.9154 17.2187C53.882 21.0532 55.8654 25.7376 55.8654 31.2721C59.2292 31.6498 62.0098 33.0665 64.207 35.5221C66.4237 37.9398 67.532 40.7732 67.532 44.0221C67.532 47.5732 66.2584 50.5859 63.7112 53.0604C61.164 55.5348 58.0626 56.7721 54.407 56.7721H38.3654C36.7709 56.7721 35.4001 56.2148 34.2529 55.1004C33.1056 54.0048 32.532 52.6732 32.532 51.1054V36.5137L27.8654 40.9054L23.782 36.9387L35.4487 25.6054L47.1154 36.9387L43.032 40.9054L38.3654 36.5137V51.1054H54.407C56.4487 51.1054 58.1695 50.4159 59.5695 49.0371C60.989 47.6771 61.6987 46.0054 61.6987 44.0221C61.6987 42.0387 60.989 40.3671 59.5695 39.0071C58.1695 37.6282 56.4487 36.9387 54.407 36.9387H50.032V31.2721C50.032 27.3621 48.6126 24.0187 45.7737 21.2421C42.9348 18.4843 39.4931 17.1054 35.4487 17.1054C31.4237 17.1054 27.982 18.4843 25.1237 21.2421C22.2848 24.0187 20.8654 27.3621 20.8654 31.2721H19.407C16.5876 31.2721 14.1862 32.2448 12.2029 34.1904C10.2001 36.1171 9.19869 38.4498 9.19869 41.1887C9.19869 43.9276 10.2001 46.2887 12.2029 48.2721C14.1862 50.1609 16.5876 51.1054 19.407 51.1054H26.6987V56.7721"
                          fill="#A3A3A3"
                        />
                      </svg>
                      <p
                        className={`text-[14px] ${theme === "dark" ? "text-white" : "text-black"
                          }`}
                      >
                        Upload logo or drag and drop <br /> (png, jpg, svg)
                      </p>
                    </div>
                  )}
                </div>
              </label>
              <span className="text-red-500">{errors.logo}</span>
              <div
                className={`flex items-center space-x-4 ${theme === "dark" ? "text-white" : "text-black"
                  }`}
              >
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={formData.is_realtime}
                      onChange={() =>
                        setFormData((prevState) => ({
                          ...prevState,
                          is_realtime: !prevState.is_realtime,
                        }))
                      }
                    />
                  }
                  label="Realtime"
                />
              </div>
              <div className="flex justify-end mt-[20px] mb-[45px]">
                <div className="flex flex-wrap gap-[16px] modal-cancel w-full lg:w-auto">
                  <button
                    className="bg-[#D9D9D9] rounded-[10px] px-[16px] py-[10px] w-full lg:w-28 text-black"
                    type="button"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    className=" text-white rounded-[10px] px-[16px] py-[10px] w-full lg:w-28 "
                    type="button"
                    onClick={handleSave}
                    style={{
                      backgroundColor:
                        localStorage.getItem("theme-color") ||
                        userData.themeColor,
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <CustomSnackbar
        openSnackbar={addBoardSnackbar}
        closeSnackbar={() => {
          setAddBoardSnackbar(false);
        }}
        message="Board successfully added with generated API key"
      />
    </div>
  );
};

export default AddBoardModal;
