import React, { useState } from "react";
import axios from 'axios';
import styles from "../../../style";
import envelope from "../../../assets/images/envelope.svg";
import "./contactUs.css";
import CustomSnackbar from "../../CustomSnackbar";
import arrowWhite from '../../../assets/images/arrow-white.svg';

const ContactUs = () => {

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [contactSnackbar, setContactSnackbar] = useState(false);
  const handleSend = async () => {
    try {

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/add-contact-us`,
        {
          fullName,
          email,
          message
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setContactSnackbar(true);
      setFullName('');
      setEmail('');
      setMessage('');
    } catch (err) {
      console.error("Error:", err);
    }
  }

  return (
    <section className={`${styles.paddingX} my-[80px]`}>
      <div className="flex flex-col sm:flex-row justify-center gap-[63px] ">
        <div className="w-full sm:w-1/3 md:w-1/2 flex flex-col gap-10 text-left mt-5">
          <h3
            className={`text-[#061C3D] text-[32px] sm:text-[42px] font-[600] font-Inter`}
          >
            Contact Us
          </h3>
          <p className="text-[#42526B] text-[16px] font-Inter font-[400] max-w-[536px] ">
            Have questions or need assistance? Our dedicated team is here to
            help you make the most of your Sensy32 experience.
          </p>

          <div className="flex items-center gap-5">
            <img src={envelope} alt="envelope" />
            <div className="flex flex-col">
              <span>Email us</span>
              <span>support@sensy32.io</span>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-2/3 md:w-1/2 form p-[48px] text-left flex-col ">

          <div className="flex flex-col gap-[10px] my-[16px]">
            <label htmlFor="name">Full Name</label>
            <input
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              type="text"
              id="name"
              className="input"
            />
          </div>

          <div className="flex flex-col gap-[10px] my-[16px]">
            <label htmlFor="email">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              id="email"
              className="input"
            />
          </div>

          <div className="flex flex-col gap-[10px]">
            <label htmlFor="message">Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              type="text"
              id="message"
              rows="3"
              className="input"
            />
          </div>

          <button className="bg-[#168004] mt-[25px] px-[30px] py-[14px] text-white font-Inter font-[600] flex items-center justify-center gap-3 max-w-[186px] rounded-[8px]" onClick={handleSend}>
            <span>Send</span>
            <img alt="arrow icon" className="rotation" src={arrowWhite}/>
          </button>
        </div>
      </div>
      <CustomSnackbar openSnackbar={contactSnackbar}
        closeSnackbar={() => { setContactSnackbar(false) }}
        message="Your request has been sent successfully"
      />
    </section>
  );
};

export default ContactUs;
