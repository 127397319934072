import React from "react";
import logo from "../../../assets/images/logo-black.svg";
import youtubeIcon from '../../../assets/icons/youtube.svg';
import githubIcon from '../../../assets/icons/github.svg';
import tindieIcon from '../../../assets/icons/tindie.svg';
import styles from "../../../style";
import "./footer.css";

export default function Footer() {

  const navigateTo = (url) =>{
        window.open(url, "_blank");

  }

  return (
    <footer className={`${styles.paddingX} flex flex-col`}>
      <div className="flex justify-center items-center xs:items-start sm:justify-between flex-wrap">
        <div className="flex gap-[80px] mb-[30px] flex-wrap justify-center sm:justify-start">
          <img
            className=" p-2 lg:ml-16 max-w-[130px] justify-center items-center"
            src={logo}
            alt="Logo"
          />
          <div className="flex flex-col text-left">
            <h6 className="text-[#343434] text-[18px] font-Poppins font-[700] mb-2">
              Quick Links
            </h6>
            <ul>
              <li  onClick={()=>navigateTo('https://sensy32.io/documentation')}>Documentation</li>
              <li onClick={() => (window.location.href = "/#embedded-sensors")}>Embedded Sensors</li>
              <li onClick={() => (window.location.href = "/#customer-reviews")}>Customer Reviews</li>
            </ul>
          </div>
          <div className="flex flex-col text-left">
            <h6 className="text-[#343434] text-[18px] font-Poppins font-[700] mb-2">
              Support
            </h6>
            <ul>
              <li onClick={() => (window.location.href = "/#contact-us")}>Contact Us</li>
              <li onClick={() => (window.location.href = "mailto:support@sensy32.io")}>Email Us</li>
            </ul>
          </div>
        </div>

        {/* RIGHT */}
        <div className="flex flex-col gap-[16px] items-center sm:items-end">
          <span className="text-right">Find Us On:</span>
          <div className="flex media-container gap-[8px]">
            <div className="media youtube" onClick={()=>navigateTo('https://www.youtube.com/@Sensy32')}>
              <img alt="youtube" src={youtubeIcon}/>
            </div>
            <div className="media github" onClick={()=>navigateTo('https://github.com/sensy32/sensy32')}>
              <img alt="github" src={githubIcon}/>
            </div>
            <div className="media tindie" onClick={()=>navigateTo('https://www.tindie.com/products/sensy32/sensy32-all-in-one-sensor-iot-board-with-lcd/')}>
              <img alt="tindie" src={tindieIcon}/>
            </div>
          </div>
        </div>
      </div>

      <span className="text-[#343434] text-[18px] font-Poppins font-[500] mt-5 sm:mt-0 text-end">
        Sensy32 2024. All rights reserved
      </span>
    </footer>
  );
}
