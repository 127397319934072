import { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import OrientationChart from "./widgets/OrientationWidget";
import PresenceWidget from "./widgets/PresenceWidget";
import PressureChart from "./widgets/PressureAltitudeChart";
import TempWidget from "./widgets/TempWidget";
import UVChart from "./widgets/UVChart";
import LightingChart from "./widgets/LightingWidget";
import axios from "axios";
import AccelerometerWidget from "./widgets/AccelerometerWidget";
const DraggableChart = ({
  chart,
  index,
  boardName,
  onCardMove,
  grow,
  onEdit,
  onDelete,
  sensor,
  width,
  fromHome
}) => {
  const [, ref] = useDrag({
    type: "CHART",
    item: { id: index },
  });
  const [sensorType, setSensorType] = useState("");
  const [sensorData, setSensorData] = useState([]);
  const [updatedAtArray, setUpdatedAtArray] = useState([]);

  // const fetchSensorData = async () => {
  //   try {
  //     const token = localStorage.getItem('token');
  //     if (!token) {
  //       console.error("Token is missing");
  //       return;
  //     }
  //     const headers = {
  //       "Content-Type": "application/json",
  //       "Authorization": `Bearer ` + token

  //     };
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_SERVER_URL}/sensors/sensor-data/${sensor}`, { headers }
  //     ); // Update with your API endpoint
  //     if (response.status === 200) {
  //       const data = response.data.value;
  //       const value = JSON.parse(data);
  //     }
  //   }
  //   catch (error) {
  //     console.error(error);
  //   }
  // };
  const fetchLastSensorData = async () => {

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/sensors/last-sensor-data/${sensor}`
        , { headers });
      if (response.status === 200) {
        const data = response.data;
        const valuesList = [];
        const updatedAtList = [];
        data.forEach((item) => {
          valuesList.push(JSON.parse(item.value));
          updatedAtList.push(item.updated_at);
        });
        setSensorType(data[0]?.sensor_type);
        setSensorData(valuesList);
        setUpdatedAtArray(updatedAtList);
      } else {
        console.error("Failed to fetch sensor data");
      }
    } catch (error) {
      console.error("Error fetching sensor data:", error);
    }
  };

  useEffect(() => {
    // fetchSensorData();
    fetchLastSensorData();
  }, [sensor]);

  const [, drop] = useDrop(() => ({
    accept: "CHART",
    hover(item, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      if (onCardMove !== undefined) {
        onCardMove(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  }));

  const handleWidget = () => {
    switch (sensorType) {
      case "temperature":
        return (
          <>
            <div className="p-4">
              <TempWidget
                value={sensorData}
                min={chart.minValue}
                max={chart.maxValue}
                text={"Temperature"}
                color={chart.color}
                grow={grow}
                onEdit={onEdit}
                onDelete={onDelete}
                chart={chart}
                sensor={"temperature"}
                widget={chart.id}
                type={chart.type}
                updatedAtArray={updatedAtArray}
                boardName={boardName}
                width={width}
                fromHome={fromHome}
              />
            </div>
          </>
        );
      case "humidity":
        return (
          <div className="p-4">
            <TempWidget
              value={sensorData}
              min={chart.minValue}
              max={chart.maxValue}
              text={"Humidity"}
              color={chart.color}
              grow={grow}
              onEdit={onEdit}
              onDelete={onDelete}
              chart={chart}
              sensor={"humidity"}
              widget={chart.id}
              type={chart.type}
              updatedAtArray={updatedAtArray}
              boardName={boardName}
              width={width}
              fromHome={fromHome}
            /></div>
        );
      case "light":
        return (
          <div className="p-4">
            <LightingChart
              value={sensorData}
              sensor="Lighting"
              color={chart.color}
              type={chart.type}
              widget={chart.id}
              onDelete={onDelete}
              updatedAtArray={updatedAtArray}
              boardName={boardName}
              width={width}
              fromHome={fromHome}
            /></div>
        );
      case "motion":
        return sensorData ? (
          <div className="p-4">
            <PresenceWidget
              value={sensorData}
              text={"Movement"}
              color={chart.color}
              sensor="motion"
              type={chart.type}
              widget={chart.id}
              onDelete={onDelete}
              updatedAtArray={updatedAtArray}
              boardName={boardName}
              width={width}
              fromHome={fromHome}
            /></div>
        ) : null;
      case "uv":
        return (
          <div className="p-4">
            <UVChart
              value={sensorData}
              color={chart.color}
              sensor="UV"
              type={chart.type}
              widget={chart.id}
              onDelete={onDelete}
              updatedAtArray={updatedAtArray}
              boardName={boardName}
              width={width}
              fromHome={fromHome}
            /></div>
        );
      case "pressure":
        return (
          <div className="p-4">
            <PressureChart
              data={sensorData}
              sensor="pressure"
              color={chart.color}
              type={chart.type}
              widget={chart.id}
              onDelete={onDelete}
              updatedAtArray={updatedAtArray}
              boardName={boardName}
              width={width}
              fromHome={fromHome}
            />
          </div>
        );
      case "altitude":
        return (
          <div className="p-4">
            <PressureChart
              data={sensorData}
              sensor="altitude"
              color={chart.color}
              type={chart.type}
              widget={chart.id}
              onDelete={onDelete}
              updatedAtArray={updatedAtArray}
              boardName={boardName}
              width={width}
              fromHome={fromHome}
            />
          </div>
        );
      case "orientation":
        return (
          <div className="p-4">
            <OrientationChart
              value={sensorData}
              sensor="Orientation"
              color={chart.color}
              type={chart.type}
              widget={chart.id}
              onDelete={onDelete}
              updatedAtArray={updatedAtArray}
              boardName={boardName}
              width={width}
              fromHome={fromHome}
            />
          </div>
        );
      case "accelerometer":
        return (
          <div className="p-4">
            <AccelerometerWidget
              value={sensorData}
              sensor="Accelerometer"
              color={chart.color}
              type={chart.type}
              widget={chart.id}
              onDelete={onDelete}
              updatedAtArray={updatedAtArray}
              boardName={boardName}
              width={width}
              fromHome={fromHome}
            /></div>
        );
      default: return (<></>)
    }
  };

  return (
    <div className="mb-5 w-full" ref={(node) => ref(drop(node))}>
      {handleWidget()}
    </div>
  );
};
export default DraggableChart;
