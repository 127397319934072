import React from "react"
import './testimonials.css';
import arrowWhite from '../../../assets/images/arrow-white.svg';

export default function Testimonials() {
    const navigateToTindie = () => {
        window.open('https://www.tindie.com/products/sensy32/sensy32-all-in-one-sensor-iot-board-with-lcd/', "_blank");
    }

    return (
        <div className="background-testimonials-section flex flex-col justify-center items-center gap-[50px]">
            <h3 className={`text-[#061C3D] text-[32px] sm:text-[42px] font-[600] font-Inter`} >Customer Reviews </h3>
            <iframe
                    className="video-testimonials"
                    src="https://www.youtube.com/embed/1qtetzBDFmk"
                    title="Sensy32: The All-in-One ESP32S3 Sensor Board with Real-Time Dashboard"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
            ></iframe>
            <button className="testimonials-section-button" onClick={()=>navigateToTindie()}>Buy Now<img alt="arrow icon" src={arrowWhite}/></button>
        </div>
    )
}
